import { useEffect } from "react";
import { useField } from "formik";
import { CheckboxGroup as NextUICheckboxGroup } from "@nextui-org/checkbox";
import { ICheckboxGroup, ICheckboxItem } from "./_type";
import Checkbox from "../checkbox";

export * from "./_type";

export default function CheckboxGroup({
  options,
  defaultValue = [],
  ...props
}: Readonly<ICheckboxGroup>) {
  const [{ value = [] }, , { setValue }] = useField(props.name as string);

  useEffect(() => {
    if (defaultValue.length && !value.length) {
      setValue(defaultValue);
    }
  }, [defaultValue, setValue, value]);

  const handleChange = (selectedValues: string[] | string) =>
    setValue(Array.isArray(selectedValues) ? selectedValues : [selectedValues]);

  return (
    <NextUICheckboxGroup {...props} value={value} onChange={handleChange}>
      {options?.map(({ value, label }: ICheckboxItem) => (
        <Checkbox
          key={value.toString()}
          value={value.toString()}
          label={label}
          id={value.toString()}
        />
      ))}
    </NextUICheckboxGroup>
  );
}
