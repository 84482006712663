import { Selection, SelectItemProps, SelectProps } from "@nextui-org/react";
import { FieldBase } from "@nvs-dynamic-form/react-core";

export interface IDropdownItem extends Omit<SelectItemProps, "key" | "title"> {
  label: string;
  value: string | number;
  disabled?: boolean;
  badge?: IDropdownBadge;
}

export interface IDropdownBadge {
  textColor: string;
  backgroundColor: string;
}

export class DropdownField extends FieldBase<string | number> {
  override readonly fieldType? = "dropdown";
  options: IDropdownItem[];
  onSelectionChange?:
    | ((event: Selection) => void)
    | ((event: Selection) => Promise<void>);
  value?: string | number;

  constructor(options: Omit<DropdownField, "fieldType">) {
    super(options);
    this.options = options.options ?? [];
    this.onSelectionChange = options.onSelectionChange;
    this.value = options.defaultValue;
  }
}

export type IDropdown = DropdownField & Omit<SelectProps, "children">;
