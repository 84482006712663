import { ICompany } from "./company";

export interface IChatApp {
  id: string;
  provider: string;
  company: ICompany;
  companyId: number;
  externalAppId: string;
  title: string;
  botName: string;
}

export enum EChatAppProvider {
  WHATSAPP = "WHATSAPP",
}
