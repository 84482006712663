import {
  ReactNode,
  createContext,
  useContext,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from "react";

import { IBreadcrumbsItem } from "../components/breadcrumbs";
import { ICurrentPageProvider } from "../models";
import { useTranslation } from "./useTranslation";

export const CurrentPageContext = createContext({});

export const CurrentPageProvider = ({ children }: { children: ReactNode }) => {
  const [breadcrumbsItems, setBreadcrumbsItems] = useState<IBreadcrumbsItem[]>(
    [],
  );
  const { t } = useTranslation();

  const setPageTitle = useCallback(
    (title: string) => {
      document.title = t("pageTitle", { pageTitle: title });
    },
    [t],
  );

  useEffect(() => {
    setBreadcrumbsItems([]);
  }, [children]);

  const contextValue = useMemo(
    () => ({
      setPageTitle,
      breadcrumbsItems,
      setBreadcrumbsItems,
    }),
    [setPageTitle, breadcrumbsItems],
  );

  return (
    <CurrentPageContext.Provider value={contextValue}>
      {children}
    </CurrentPageContext.Provider>
  );
};

export const useCurrentPage = () =>
  useContext(CurrentPageContext) as ICurrentPageProvider;
